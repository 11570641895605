.container-login100 {
  height: 100vh;
  .wrap-login100 {
    .login100-form-wrap {
      width: 50%;
      position: relative;
      padding: 20px;

      .auth-content-logo-img {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 220px;
        max-height: 38px;

        img{
          width: 100%;
        }

        span {
          margin-left: 10px;
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 37px;
        }
      }

      .auth-content-form {
        max-width: 430px;
        width: 100%;
        margin-top: 60px;

        .custom-password-field {
          .form-control {
            padding-right: 50px;
          }

          .show-password-wrap {
            position: absolute;
            right: 15px;
            top: 15%;
            font-size: 24px;
            opacity: 0.5;
            cursor: pointer;
            z-index: 4;
          }
        }

        .custom-form-group {
          label {
            &:before {
              border-radius: 100%;
              box-shadow: none;
            }
          }

          label, a {
            color: $primaryColor;
            opacity: 0.5;
          }
        }

        .submit-block {
          .btn {
            height: 50px;
            line-height: 50px;
            background-color: $primaryColor;
            color: #fff;
            font-size: 20px;
            text-align: center;
            width: 100%;
            border-radius: 100px;
            padding: 0;
            box-shadow: none;
            border: none;
          }
        }
      }
      .shape {
        display: none;
        position: absolute;
        &_blue {
          width: 250px;
          height: 188px;
          bottom: 0;
          right: 0;
          background-color: rgba($secondColor, .5);
          border-top-left-radius: 50%;
          z-index: 2;
        }
        &_pink {
          width: 164px;
          height: 150px;
          bottom: 75px;
          right: 170px;
          background-color:rgba(235, 87, 87, .5);
          border-top-left-radius: 50px;
          border-bottom-right-radius: 50px;
          z-index: 1;
        }
      }
    }

    .login100-logo-wrap {
      background-color: $primaryColor;
      width: 50%;
      padding: 30px;

      .login100-logo-img {
        max-width: 480px;
      }

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

  }
}



.login-page{
}

.forgot-pass-page{
}

@media (max-width: 1199px) {
  .login100-logo-img {
    max-width: 350px;
  }
}
@media (max-width: 767px) {
  .container-login100 {
    .wrap-login100 {
      .login100-logo-wrap {
        display: none!important;
      }
      .login100-form-wrap {
        width: 100%;

        .auth-content-form {
          margin-top: 30px;
        }

        .form-group {
          margin-bottom: 20px;
        }

        .shape {
          display: block;
        }
      }
    }
  }
}
@media (max-width: 767px) and (max-height: 800px) {
  .container-login100 {
    .wrap-login100 {
      .login100-form-wrap {

        .shape {
          &_blue {
            width: 220px;
            height: 150px;
          }
          &_pink {
            width: 134px;
            height: 120px;
            right: 146px;
            border-top-left-radius: 40px;
            border-bottom-right-radius: 40px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) and (max-height: 735px) {
  .container-login100 {
    .wrap-login100 {
      .login100-form-wrap {

        .shape {
          &_blue {
            width: 130px;
            height: 80px;
          }
          &_pink {
            width: 86px;
            height: 78px;
            bottom: 27px;
            right: 78px;
            border-top-left-radius: 25px;
            border-bottom-right-radius: 25px;
          }
        }
      }
    }
  }
}
