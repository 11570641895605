
.register{
  .btnSubmit:last-child{
    float: none !important;
  }

  form{
    padding: 0;
  }
}

.back-button {
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 30px;

  a {
    color: #163d64;
    margin-left: 10px;
  }
}

