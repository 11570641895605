html, body {
  height: 100%;
}

a {
  color: $primaryColor;

  &:hover {
    text-decoration: none;
    color: $primaryColor;
  }
}

.form {
  .form-group {
    margin-bottom: 35px;

    label {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
    }

    .form-control {
      border: none;
      border-radius: 10px;
      background: $gray-100;
      font-size: 18px;
      color: $primaryColor;
      height: 50px;
      padding: 15px 20px;

      &:focus {
        box-shadow: 0 0 0 0.1rem rgba(29, 56, 147, 0.25);
      }

      &::placeholder {
        color: #8E9BC9;
      }

      &.is-invalid {
        box-shadow: 0 0 0 0.1rem rgb(254, 107, 117);
      }
    }
  }
}

.btn {
  height: 50px;
  line-height: 25px;
  display: inline-block;
  border: none;
}

.error{
  color: red;
  font-size: 13px;
}
