.container-homepage {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &>.auth-content-logo {
    max-width: 850px;
    width: 100%;
    padding: 0 50px;
  }

  &>.homepage-buttons {
    position: absolute;
    top: 50px;
    right: 50px;

    .btn {
      height: auto;
    }
  }

  img {
    width: 100%;
  }
}
