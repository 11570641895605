$primaryColor: #15b1d7;
$secondColor: #f97304;
$gray-100: #F9FAFC;
$gray-200: #F1F5F8;
$dangerColor: #FE6B75;

$body-color: $primaryColor;
$theme-colors: (
  "primary": $primaryColor,
  "secondary": $secondColor,
  "danger": $dangerColor
);

